.leaderboard-row:hover {
    background-color: #f8f8f8;
}

.profile-name {
    width: 100%;
    padding-left: 20px;
}

.profile-score {
    width: 25%;
    font-size: 150%; 
    text-align: center;
    min-width: 70px;
}

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .profile-name {
        width: 100%;
        padding-left: 20px;
        font-size: 70%;
    }

    .profile-score {
        font-size: 100%; 
    }
}

@media (max-width: 576px) {

}

@media (max-width: 360px) {

}