#nav {
    background-color: transparent;
}

#navTray {
 display: grid;
 grid-template-columns: 1fr 1;
}

#notificationTrayItem {
    grid-column: 1 / 2;
}

#scoreTrayItem {
    grid-column: 2 / 2;
}

.flexEnd {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.heroesLegend {
    font-family: 'heroesLegend', sans-serif;
}

.blur {
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.subtle {
    color: lightblue;
}

.profileLink {
    color: lightblue;
}

.profileLink:hover {
    text-decoration: underline;
}

.pageTitle {
    font-size: 24px;
}

#navBrand {
    font-size: 100%;
}

#footer {
    background: url('../img/footer.png');
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: right bottom;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .pageTitle {
        font-size: 32px;
    }
    
    #navBrand {
        font-size: 100%;
    }
    
    #navTray {
        grid-template-columns: 0 1fr;
    }
    
    #notificationTrayItem {
        grid-column: 2 / 2;
        grid-row: 2 / 2;
    
    }
    
    #scoreTrayItem {
        grid-column: 2 / 2;
        grid-row: 1 / 2;
    }
}

@media (max-width: 576px) {
    .pageTitle {
        font-size: 24px;
    }
    
    #navBrand {
        font-size: 70%;
    }

}

@media (max-width: 360px) {

}