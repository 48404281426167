.smallText {
    font-size: 100%;
}

.longHex {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;
}


@media (max-width: 1400px) {

}

@media (max-width: 1200px) {
    .longHex {
        font-size: 100%;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .smallText {
        font-size: 70%;
    }
}

@media (max-width: 576px) {
    .longHex {
        font-size: 70%;
    }
}

@media (max-width: 360px) {

}