#profileImage {
    border-radius: 50%;
    height: 400px;
    width: 400px;
    margin-right: 24px; 
}

#metricTray {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 50px;
}

#profileTray {
    display: flex;
    flex-wrap: nowrap;
}

#username {
    word-wrap: break-word;
    font-family: Roboto;
    font-size: 230%;
}

#profileLink {
    font-size: 200%;
    color: lightblue;
}

.metricValue {
    font-family: Roboto;
    font-size: 200%;
}

.metricBox {
    margin-right: 15px; 
    height: 150px; 
    width: 150px; 
    padding: 15px;
    border-radius: 10px; 
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1400px) {
    #profileImage {
        height: 200px;
        width: 200px;
    }

    #metricTray {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media (max-width: 1200px) {
    #metricTray {
        padding-left: 0px;
        padding-right: 0px;
    }

    #username {
        font-size: 200%;
    }
}

@media (max-width: 992px) {
    .metricValue {
        font-size: 100%;
    }

    #username {
        font-size: 120%;
    }

    #profileLink {
        font-size: 100%;
    }
}

@media (max-width: 768px) {
    #metricTray {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        row-gap: 15px;
    }

    .metricBox {
        margin: 0;
        height: 100px; 
        width: 100px; 
        align-self: center;
        justify-self: center;
    }

    .metricId {
        font-size: 70%;
    }

    .metricValue {
        font-size: 70%;
    }
    
    #profileImage {
        margin-bottom: 15px;
    }
    
    #profileTray {
        display: block;
    }
}

@media (max-width: 576px) {

    #metricTray {
        grid-template-columns: 33% 33% 33%;
    }

}

@media (max-width: 380px) {
    
    .metricBox {
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 320px) {
    
    /* .metricBox {
        padding: 5px;
        width: 100px;
        height: 100px;
    } */
}