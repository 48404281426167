#likeRewardsPanel {
    background: radial-gradient(#e2aa9d, #d89483);
    padding: 25px;
}

#rewardsTray {
    display: flex;
    justify-content: space-around;
}

input[type='text']:focus {
    background-color: transparent;
    outline: none;
}

.rewardBoxes {
    width: 250px;
    height: 325px;
    margin: 3px;
    background: white;
    padding: 15px;
    border-radius: 10px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.rewardTitle {
    text-align: center;
}

.panelTitle {
    text-align: center;
    font-size: 200%;
    color: white;
}

.panelDescription {
    text-align: center;
    color: white;
}

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    #rewardsTray {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 576px) {

}

@media (max-width: 380px) {

}

@media (max-width: 320px) {

}