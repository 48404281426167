@keyframes notification {
    0% { width: 10px; height: 10px; top: -5px; right: -5px;}
    50% { width: 20px; height: 20px; top: -10px; right: -10px; }
    100% { width: 10px; height: 10px; top: -5px; right: -5px; }
}

.notification {
    animation-name: notification;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}