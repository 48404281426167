body {
    margin: 0;
    font-family: 'Comfortaa';
    /* , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: url('../img/footer.png'); */
    /* background-size: cover; */
    /* background: radial-gradient(100% 100% at 50% 100%, #84F1EE 0%, #078DB4 100%); */
    background: white;
    /* min-height: 100vh; */
    /* background-repeat: no-repeat; */
    /* background-position: bottom; */
    color: black;
    transition: all 0.3s ease-in-out;
}

/* deactivate link colors */
a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
}

@font-face {
    font-family: "heroesLegend";
    src: url('../font/Heroes\ Legend.woff2') format("woff2");
}