#background {
    background: url('../img/landingbg.png');
    background-size: cover;
    /* min-width: 100vw; */
    background-repeat: no-repeat;
    background-position: top;
}

#gameSplash {
    color: white;
    background: url('../img/arcade_large.png');
    background-size: cover;
    /* min-width: 100vw; */
    background-repeat: no-repeat;
    background-position: left;
    height: 450px;
}

#mainTitle {
    font-size: 48px;
    display: inline-block;
    width: auto;
}

#introText {
    font-size: 100%;
    text-align: justify;
    width: 522px;
    max-width: 90vw;
}

#launchButton {
    width: 280px;
    height: 58px;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    #mainTitle {
        font-size: 32px;
    }

    #introText {
        width: 350px;
        font-size: 70%;
    }
}

@media (max-width: 576px) {
    #mainTitle {
        font-size: 24px;
    }

    #introText {
        width: 262px;
    }
    
    #launchButton {
        width: 160px;
        height: 36px;
        font-size: 70%;
    }
}

@media (max-width: 360px) {
    #mainTitle {
        font-size: 16px;
    }
}