.rewardPanel {
    min-height: 356px;
}

.dailyQuest {
    color: white;
    background: url('../img/chest_M.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
}

.followFounder {
    background: url('../img/carlosBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
}

.followFlock {
    background: url('../img/flockBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
}

.rewardPill {
    position: absolute; 
    background: red; 
    bottom: 0px; 
    right: 0px; 
    padding: 5px; 
    font-family: Roboto; 
    color: white; 
    border-radius: 5px;
    font-size: 70%;
}
.targetProfile {
    font-family: Roboto;
    font-size: 70%;
    word-break: break-all;
}

.claimButton {
    background-color: #fff;
    /* padding: 5px 5px; */
    /* border-radius: 5px; */
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    position: relative;
    cursor: pointer;
}

.claimButton:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background-color: #e8e8e8;
    transition: all 0.3s ease-in-out;
}

.likeButton {    
    background: #eee;
    color: black;
    height: 50px;
    width: 100px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.25);
    transition: all 0.3s ease-in-out ;
}

.likeButton:hover {
    color: red;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
    
}

.followButton {    
    background: #eee;
    color: black;
    height: 50px;
    width: 150px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.25);
    transition: all 0.3s ease-in-out ;
}

.followButton:hover {
    color: #2BABCB;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
    
}

.extraPanel {
    text-align: center; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.extraPanel:hover {
    color: #2BABCB;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
    transition: all 0.3s ease-in-out ;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    .targetProfile {
        font-family: Roboto;
        font-size: 70%;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}

@media (max-width: 360px) {

}